import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import Layout from 'components/Layout'
import Seo from 'components/Seo'

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: auto;
`

const TermsHeader = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`

const TermsUpdated = styled.p`
  font-weight: normal;
  font-style: italic;
  margin-bottom: 1em;
  text-align: center;
  font-size: 1.2rem;
`

const TermsBody = styled.ol`
  margin: 0 3em 0 3em;
  font-weight: bold;
  font-size: 1.1rem;
  list-style-type: none;
`

const TermsSection = styled.li`
  margin: 1em 0 1em 0;
`

const TermsSectionTitle = styled.div`
  font-weight: bold;
`

const TermsSectionText = styled.p`
  font-weight: normal;
  margin-bottom: 1em;
  text-align: justify;
`

const TermsSectionUnorderedList = styled.ul`
  margin-left: 3em;
`
const TermsSectionListItem = styled.li``

const Terms = () => {
  const { t } = useTranslation('termsOfUse')
  return (
    <Layout>
      <Seo title={t('title')} description={t('description')} />
      <TermsContainer>
        <TermsHeader>{t('header')}</TermsHeader>
        <TermsUpdated>{t('lastUpdated')}</TermsUpdated>
        <TermsBody>
          <TermsSection>
            <TermsSectionText>{t('overview.one')}</TermsSectionText>
            <TermsSectionText>{t('overview.two')}</TermsSectionText>
            <TermsSectionText>{t('overview.three')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'purchases'}>
            <TermsSectionTitle>{t('purchases.title')}</TermsSectionTitle>
            <TermsSectionText>{t('purchases.one')}</TermsSectionText>
            <TermsSectionText>{t('purchases.two')}</TermsSectionText>
            <TermsSectionText>{t('purchases.three')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'error-fixes'}>
            <TermsSectionTitle>{t('errorFixes.title')}</TermsSectionTitle>
            <TermsSectionText>{t('errorFixes.one')}</TermsSectionText>
            <TermsSectionText>{t('errorFixes.two')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'subscriptions'}>
            <TermsSectionTitle>{t('subscriptions.title')}</TermsSectionTitle>
            <TermsSectionText>{t('subscriptions.one')}</TermsSectionText>
            <TermsSectionText>{t('subscriptions.two')}</TermsSectionText>
            <TermsSectionText>{t('subscriptions.three')}</TermsSectionText>
            <TermsSectionText>{t('subscriptions.four')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'free-trial'}>
            <TermsSectionTitle>{t('freeTrial.title')}</TermsSectionTitle>
            <TermsSectionText>{t('freeTrial.one')}</TermsSectionText>
            <TermsSectionText>{t('freeTrial.two')}</TermsSectionText>
            <TermsSectionText>{t('freeTrial.three')}</TermsSectionText>
            <TermsSectionText>{t('freeTrial.four')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'fee-changes'}>
            <TermsSectionTitle>{t('feeChanges.title')}</TermsSectionTitle>
            <TermsSectionText>{t('feeChanges.one')}</TermsSectionText>
            <TermsSectionText>{t('feeChanges.two')}</TermsSectionText>
            <TermsSectionText>{t('feeChanges.three')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'refunds'}>
            <TermsSectionTitle>{t('refunds.title')}</TermsSectionTitle>
            <TermsSectionText>{t('refunds.one')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'accounts'}>
            <TermsSectionTitle>{t('accounts.title')}</TermsSectionTitle>
            <TermsSectionText>{t('accounts.one')}</TermsSectionText>
            <TermsSectionText>{t('accounts.two')}</TermsSectionText>
            <TermsSectionText>{t('accounts.three')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'copyright'}>
            <TermsSectionTitle>{t('copyright.title')}</TermsSectionTitle>
            <TermsSectionText>{t('copyright.one')}</TermsSectionText>
            <TermsSectionText>{t('copyright.two')}</TermsSectionText>
            <TermsSectionText>{t('copyright.three')}</TermsSectionText>
          </TermsSection>
          <TermsSection id={'dmca'}>
            <TermsSectionTitle>{t('dmca.title')}</TermsSectionTitle>
            <TermsSectionText>
              {t('dmca.one.overview')}
              <TermsSectionUnorderedList>
                <TermsSectionListItem>
                  {t('dmca.one.listItem1')}
                </TermsSectionListItem>
                <TermsSectionListItem>
                  {t('dmca.one.listItem2')}
                </TermsSectionListItem>
                <TermsSectionListItem>
                  {t('dmca.one.listItem3')}
                </TermsSectionListItem>
                <TermsSectionListItem>
                  {t('dmca.one.listItem4')}
                </TermsSectionListItem>
                <TermsSectionListItem>
                  {t('dmca.one.listItem5')}
                </TermsSectionListItem>
                <TermsSectionListItem>
                  {t('dmca.one.listItem6')}
                </TermsSectionListItem>
              </TermsSectionUnorderedList>
            </TermsSectionText>
            <TermsSectionText>{t('dmca.two')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'intellectual-property'}>
            <TermsSectionTitle>
              {t('intellectualProperty.title')}
            </TermsSectionTitle>
            <TermsSectionText>{t('intellectualProperty.one')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'links'}>
            <TermsSectionTitle>{t('links.title')}</TermsSectionTitle>
            <TermsSectionText>{t('links.one')}</TermsSectionText>
            <TermsSectionText>{t('links.two')}</TermsSectionText>
            <TermsSectionText>{t('links.three')}</TermsSectionText>
            <TermsSectionText>{t('links.four')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'termination'}>
            <TermsSectionTitle>{t('termination.title')}</TermsSectionTitle>
            <TermsSectionText>{t('termination.one')}</TermsSectionText>
            <TermsSectionText>{t('termination.two')}</TermsSectionText>
            <TermsSectionText>{t('termination.three')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'indemnification'}>
            <TermsSectionTitle>{t('indemnification.title')}</TermsSectionTitle>
            <TermsSectionText>{t('indemnification.one')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'limitation'}>
            <TermsSectionTitle>{t('limitation.title')}</TermsSectionTitle>
            <TermsSectionText>{t('limitation.one')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'disclaimer'}>
            <TermsSectionTitle>{t('disclaimer.title')}</TermsSectionTitle>
            <TermsSectionText>{t('disclaimer.one')}</TermsSectionText>
            <TermsSectionText>{t('disclaimer.two')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'exclusions'}>
            <TermsSectionTitle>{t('exclusions.title')}</TermsSectionTitle>
            <TermsSectionText>{t('exclusions.one')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'governing-law'}>
            <TermsSectionTitle>{t('governingLaw.title')}</TermsSectionTitle>
            <TermsSectionText>{t('governingLaw.one')}</TermsSectionText>
            <TermsSectionText>{t('governingLaw.two')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'changes'}>
            <TermsSectionTitle>{t('changes.title')}</TermsSectionTitle>
            <TermsSectionText>{t('changes.one')}</TermsSectionText>
            <TermsSectionText>{t('changes.two')}</TermsSectionText>
          </TermsSection>

          <TermsSection id={'contact'}>
            <TermsSectionTitle>{t('contact.title')}</TermsSectionTitle>
            <TermsSectionText>{t('contact.one')}</TermsSectionText>
          </TermsSection>
        </TermsBody>
      </TermsContainer>
    </Layout>
  )
}

export default Terms
